<template>
  <div>
    <header>
      <div class="banner">
        <the-header-menu class="menus" />

        <ul class="menu">
          <li :class="[bannerActive === 0 ? 'active' : '', 'menu1']">
            <div class="text-white w-[1140px]">
              <h1 class="text-[56px] text-center">一站式IT运维解决方案</h1>
              <p class="text-[20px] text-center leading-[46px]">
                全面监控交换机、防火墙、服务器、虚拟机、中间件、数据库以及业务系统等信息化资产，并具备强大的实时可视化监控功能，让您获得对整个信息化系统状态的完全掌握
              </p>
              <div>
                <img
                  class="w-[603px] h-[361px] mx-auto translate-x-3"
                  src="./../../assets/images/macbook.png"
                />
              </div>
            </div>
          </li>
          <li :class="[bannerActive === 1 ? 'active' : '', 'menu2']">
            <div class="flex justify-between text-white w-[1140px]">
              <div class="pt-[171px] w-[523px]">
                <h1 class="text-[56px] leading-[68px] font-bold">
                  简单易懂的操作体验以及实用的特色功能
                </h1>
                <p class="text-[20px] leading-[46px] mt-10">
                  三级页面的整体平台设计清晰易懂，便捷的转跳设计以及诸多个性化的扩展功能，保证用户用的省心。
                </p>
              </div>
              <div class="pt-[47px]">
                <img
                  class="w-[641px] h-[666px] translate-x-16"
                  src="./../../assets/images/home-banner-decoration1.png"
                />
              </div>
            </div>
          </li>
          <li :class="[bannerActive === 2 ? 'active' : '', 'menu3']">
            <div class="text-white w-[1140px]">
              <h1 class="text-[56px] text-center leading-[68px]">
                更精准的状态数据
              </h1>
              <p class="text-[20px] text-center leading-[46px] mt-[40px]">
                10秒采集频率、1个月历史曲线、精准的监测数据以及及时的告警反馈为您的业务的稳定运行保驾护航
              </p>
              <div class="menu3-block">
                <div class="flex">
                  <div
                    class="flex p-8 w-[466px] h-[162px] bg-white rounded-[16px] border border-[#E5EAF4]"
                  >
                    <div class="shrink-0">
                      <img
                        class="w-[40px] h-[40px] border-dashed border"
                        src="./../../assets/images/icon_Rocket.png"
                      />
                    </div>
                    <div class="w-[24px]"></div>
                    <div class="w-[350px]">
                      <p
                        class="title-text-color text-[22px] leading-[33px] font-[500]"
                      >
                        更快速
                      </p>
                      <p class="p-text-color text-[18px] leading-[32px]">
                        信息化系统资产集中监测集中分析，状态情况获取更及时
                      </p>
                    </div>
                  </div>
                  <div class="w-[24px]"></div>
                  <div
                    class="flex p-8 w-[466px] h-[162px] bg-white rounded-[16px] border border-[#E5EAF4]"
                  >
                    <div class="shrink-0">
                      <img
                        class="w-[40px] h-[40px] border-dashed border"
                        src="./../../assets/images/icon_Workflow.png"
                      />
                    </div>
                    <div class="w-[24px]"></div>
                    <div class="w-[350px]">
                      <p
                        class="title-text-color text-[22px] leading-[33px] font-[500]"
                      >
                        更精准
                      </p>
                      <p class="p-text-color text-[18px] leading-[32px]">
                        每10S收集一次资产底层状态信息，数据精度更高更真实
                      </p>
                    </div>
                  </div>
                </div>
                <div class="flex mt-[16px]">
                  <div
                    class="flex p-8 w-[466px] h-[162px] bg-white rounded-[16px] border border-[#E5EAF4] shadow-[0_15px_35px_rgba(0,0,0,0.05)]"
                  >
                    <div class="shrink-0">
                      <img
                        class="w-[40px] h-[40px] border-dashed border"
                        src="./../../assets/images/icon_book.png"
                      />
                    </div>
                    <div class="w-[24px]"></div>
                    <div class="w-[350px]">
                      <p
                        class="title-text-color text-[22px] leading-[33px] font-[500]"
                      >
                        更简洁
                      </p>
                      <p class="p-text-color text-[18px] leading-[32px]">
                        无需手工逐步配置操作系统、软件环境依赖以及复杂参数配置等操作和校验。
                      </p>
                    </div>
                  </div>
                  <div class="w-[24px]"></div>
                  <div
                    class="flex p-8 w-[466px] h-[162px] bg-white rounded-[16px] border border-[#E5EAF4] shadow-[0_15px_35px_rgba(0,0,0,0.05)]"
                  >
                    <div class="shrink-0">
                      <img
                        class="w-[40px] h-[40px] border-dashed border"
                        src="./../../assets/images/icon_like2.png"
                      />
                    </div>
                    <div class="w-[24px]"></div>
                    <div class="w-[350px]">
                      <p
                        class="title-text-color text-[22px] leading-[33px] font-[500]"
                      >
                        更丰富
                      </p>
                      <p class="p-text-color text-[18px] leading-[32px]">
                        根据多年运维经验在产品内融合了多种运维功能，用户日常运维省时省力更省心。
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>

      <!-- 小圆点 -->
      <ul class="banner-touch">
        <li
          v-for="(item, i) in bannerListDom"
          :key="i"
          :class="i === bannerActive ? 'active' : ''"
          @click="bannerActive = i"
        ></li>
      </ul>
    </header>

    <!-- 产品中心 -->
    <section class="product pt-[66px]">
      <h1 class="text-center text-4xl title-text-color">产品中心</h1>
      <div class="mt-[50px] w-[1140px] mx-auto">
        <li class="flex justify-between w-[1140px]">
          <div class="pt-[50px]">
            <img
              class="w-[543px] h-[341px]"
              src="./../../assets/images/product-bg.png"
            />
          </div>
          <div class="w-[527px]">
            <h2 class="title-text-color text-[30px] leading-[3rem]">
              智慧IT运维管理系统
            </h2>
            <p class="p-text-color text-[18px] leading-8 pt-[68px] pr-[69px]">
              智慧IT运维管理系统通过数据高效收集以及准确分析，不仅自动简单化了许多传统运维繁琐的步骤，让运维人员可以专注于更高级的问题，还通过实时的数据分析和预测性维护，提前预警可能的问题，从而防止了可能的故障和停机时间，提升IT运维的效率。
            </p>
            <div class="pt-[40px] flex w-[500px] justify-between">
              <div class="w-[262px] h-152px">
                <img
                  class="w-[3rem] h-[3rem]"
                  src="./../../assets/images/icon_problem.png"
                />
                <p class="title-text-color mt-[19px] text-[20px] leading-6">
                  发现问题
                </p>
              </div>
              <div class="w-[262px] h-152px">
                <img
                  class="w-[3rem] h-[3rem]"
                  src="./../../assets/images/icon_sync.png"
                />
                <p class="title-text-color mt-[19px] text-[20px] leading-6">
                  处理问题
                </p>
              </div>
            </div>
            <div class="relative pt-[1rem] mt-[26px]">
              <div
                class="cursor-pointer pl-[24px] font-bold text-white primary-bg-color w-[212px] h-[54px] rounded-lg leading-[54px]"
              >
                <a href="./service_and_support.html#/contact_us">
                  申请试用&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;→</a
                >
              </div>
              <img
                class="z-[-1] w-[86px] h-[102px] absolute left-[155px] top-0"
                src="./../../assets/images/bg-oval.png"
              />
            </div>
          </div>
        </li>
      </div>
    </section>

    <!-- 解决方案 -->
    <section class="solution">
      <div class="w-[1140px] mx-auto pt-[90px]">
        <h1 class="text-center text-4xl title-text-color">解决方案</h1>
        <h2 class="text-center text-3xl mt-[50px] title-text-color">
          为什么选择数创信息智慧运维系统？
        </h2>
        <p class="text-lg text-center mt-6 p-text-color">
          可以深入了解应用和IT基础架构的性能和用户使用体验。它可以帮助您在客户受到影响之前，以最小的代价快速隔离和解决整个应用程序堆栈中的性能问题
        </p>
        <div class="mt-[35px] flex">
          <div class="w-2/6 flex pr-[30px]">
            <img
              src="./../../assets/images/icon_home.png"
              class="w-[48px] h-[50px]"
            />
            <div class="w-[24px]"></div>
            <div class="w-[300px]">
              <h3 class="text-[22px] leading-8 title-text-color">
                应用性能监控
              </h3>
              <p class="text-[18px] leading-8 mt-[8px] p-text-color">
                应用性能监控不仅仅是使用监测技术解决指标监控，还可确保您的业务按预期运行。
              </p>
            </div>
          </div>
          <div class="w-2/6 flex pr-[30px]">
            <img
              src="./../../assets/images/icon_website.png"
              class="w-[48px] h-[50px]"
            />
            <div class="w-[24px]"></div>
            <div class="w-[300px]">
              <h3 class="text-[22px] leading-8 title-text-color">
                网络状态监测
              </h3>
              <p class="text-[18px] leading-8 mt-[8px] p-text-color">
                实时监测网络设备状态以及整体网络流量及质量分析，防范网络中断于未然。
              </p>
            </div>
          </div>
          <div class="w-2/6 flex pr-[30px]">
            <img
              src="./../../assets/images/icon_website.png"
              class="w-[48px] h-[50px]"
            />
            <div class="w-[24px]"></div>
            <div class="w-[300px]">
              <h3 class="text-[22px] leading-8 title-text-color">系统监控</h3>
              <p class="text-[18px] leading-8 mt-[8px] p-text-color">
                通过监测虚拟化环境的虚拟机或物理服务器运行状况和性能问题防止服务器意外宕机，确保设备处于最优状态运行
              </p>
            </div>
          </div>
        </div>
        <div class="mt-[35px] flex">
          <div class="w-2/6 flex pr-[30px]">
            <img
              src="./../../assets/images/icon_multiple.png"
              class="w-[48px] h-[50px]"
            />
            <div class="w-[24px]"></div>
            <div class="w-[300px]">
              <h3 class="text-[22px] leading-8 title-text-color">
                运维工作简化
              </h3>
              <p class="text-[18px] leading-8 mt-[8px] p-text-color">
                智慧IT运维管理系统根据多年运维经验提供了日常IT运维管理所需的常用工具，让运维工作变得更加得心应手
              </p>
            </div>
          </div>
          <div class="w-2/6 flex pr-[30px]">
            <img
              src="./../../assets/images/icon_neutron.png"
              class="w-[48px] h-[50px]"
            />
            <div class="w-[24px]"></div>
            <div class="w-[300px]">
              <h3 class="text-[22px] leading-8 title-text-color">
                数据可视化展示
              </h3>
              <p class="text-[18px] leading-8 mt-[8px] p-text-color">
                配备出色的大屏可视化功能，通过直观、清晰的图形化界面，将IT系统的运行状态以可视化的方式呈现给运维人员，一目了然地掌握整个IT系统的运行概况
              </p>
            </div>
          </div>
          <div class="w-2/6 flex pr-[30px]">
            <img
              src="./../../assets/images/icon_like.png"
              class="w-[48px] h-[50px]"
            />
            <div class="w-[24px]"></div>
            <div class="w-[300px]">
              <h3 class="text-[22px] leading-8 title-text-color">
                风险及时通知
              </h3>
              <p class="text-[18px] leading-8 mt-[8px] p-text-color">
                通过分析实时状态数据，一旦发现相关资产发生异常或潜在风险时，第一时间向运维人员发送告警信息，确保运维团队能够迅速获知风险并采取相应措施
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="serve">
      <h1 class="text-center text-4xl title-text-color">应用场景</h1>
      <div class="flex w-[1140px] mx-auto">
        <div class="w-[600px] pt-[248px]">
          <h2 class="text-[36px] leading-[48px] title-text-color">
            IT智慧运维，为信息化系统<br />正常运行保障护航
          </h2>
          <p
            class="w-[458px] pt-[20px] text-[18px] leading-[32px] p-text-color"
          >
            智慧IT运维平台多场景适配以及其高效、智能、全面的运维服务，大幅提升了企业的运维效率，保障了业务的连续性和稳定性。同时通过及时数据收集分析，间接帮助企业制定科学的运维策略，保障信息化系统正常运行。
          </p>
          <ul class="mt-[33px] flex flex-wrap w-[600px]">
            <li class="w-1/2 flex mb-[26px]">
              <img
                class="w-[24px] h-[24px]"
                src="./../../assets/images/icon-check.png"
              />
              <div class="w-[20px]"></div>
              <span class="title-text-color">数通网络场景</span>
            </li>
            <li class="w-1/2 flex mb-[26px]">
              <img
                class="w-[24px] h-[24px]"
                src="./../../assets/images/icon-check.png"
              />
              <div class="w-[20px]"></div>
              <span class="title-text-color">业务保障场景</span>
            </li>
            <li class="w-1/2 flex mb-[26px]">
              <img
                class="w-[24px] h-[24px]"
                src="./../../assets/images/icon-check.png"
              />
              <div class="w-[20px]"></div>
              <span class="title-text-color">数据中心场景</span>
            </li>
            <li class="w-1/2 flex mb-[26px]">
              <img
                class="w-[24px] h-[24px]"
                src="./../../assets/images/icon-check.png"
              />
              <div class="w-[20px]"></div>
              <span class="title-text-color">综合业务场景</span>
            </li>
          </ul>
        </div>
        <div>
          <img
            class="w-[600px] h-[821px]"
            src="./../../assets/images/serve-img.png"
          />
        </div>
      </div>
    </section>

    <!-- 底部 -->
    <footer>
      <the-footer />
    </footer>
  </div>
</template>

<script>
import TheFooter from "@/components/TheFooter.vue";
import TheHeaderMenu from "@/components/TheHeaderMenu.vue";

export default {
  name: "App",
  components: {
    TheHeaderMenu,
    TheFooter,
  },
  data: () => {
    return {
      bannerActive: 0,
      menuDom: null,
      bannerListDom: null,
      time1: null,
    };
  },
  mounted: function () {
    this.menuDom = document.querySelector(".menu");
    this.bannerListDom = this.menuDom.querySelectorAll("li");
    this.time1 = setInterval(() => {
      this.setBannerAnimation();
    }, 5000);
  },
  onUnmounted: function () {
    clearInterval(this.time1);
  },
  methods: {
    setBannerAnimation: function () {
      this.bannerActive = (this.bannerActive + 1) % this.bannerListDom.length;
    },
  },
};
</script>

<style lang="scss" scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

header {
  height: 739px;
}

.banner {
  position: relative;
  height: 100%;
  .menus {
    left: 50%;
    position: absolute;
    z-index: 10;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  .menu {
    .active {
      z-index: 2;
      opacity: 1;
    }

    li {
      position: absolute;
      top: 0;
      left: 50%;
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);
      background-position: center;
      opacity: 0;
      height: 739px;
      width: 2560px;
      background-size: 2560px 739px;
      transition: opacity ease-in-out 1s;
      & > div {
        margin: 0 auto;
      }
    }
    .menu1 {
      padding-top: 125px;
      background-image: url(./../../assets/images/home-banner1.png);
      div {
        margin-top: 15px;
      }
    }
    .menu2 {
      background-image: url(./../../assets/images/home-banner2.png);
    }
    .menu3 {
      padding-top: 125px;
      background-image: url(./../../assets/images/home-banner3.png);
      .menu3-block {
        margin: 40px auto 0;
        width: 956px;
      }
    }
  }
}

.banner-touch {
  position: absolute;
  z-index: 5;
  left: 50%;
  transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  top: 694px;
  display: flex;
  justify-content: center;

  .active {
    background-color: rgb(21, 101, 216);
  }

  li {
    cursor: pointer;
    background-color: rgba(21, 101, 216, 0.3);
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin-right: 9px;
    transform: translateX(5px);
    -ms-transform: translateX(5px);
  }
}

.product {
  height: 691px;
}

.product-touch {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  gap: 9px;

  .active {
    background-color: rgb(21, 101, 216);
    z-index: 10;
  }

  li {
    cursor: pointer;
    background-color: rgba(21, 101, 216, 0.3);
    width: 16px;
    height: 16px;
    border-radius: 50%;
  }
}

.solution {
  margin-top: 86px;
  height: 821px;
  background-color: rgba(248, 251, 255, 1);
}

.serve {
  margin: 94px 0;
  height: 821px;
}
</style>
